@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .w-25 {
      width: 25%;
    }
  }


  .custom-border {
    border-left: 1px solid white;
  }
  
  